export const Advertisement = () => import('../../components/base/Advertisement.vue' /* webpackChunkName: "components/advertisement" */).then(c => wrapFunctional(c.default || c))
export const Advertisements = () => import('../../components/base/Advertisements.vue' /* webpackChunkName: "components/advertisements" */).then(c => wrapFunctional(c.default || c))
export const Card = () => import('../../components/base/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const CollapsableHeading = () => import('../../components/base/CollapsableHeading.vue' /* webpackChunkName: "components/collapsable-heading" */).then(c => wrapFunctional(c.default || c))
export const CollapseItem = () => import('../../components/base/CollapseItem.vue' /* webpackChunkName: "components/collapse-item" */).then(c => wrapFunctional(c.default || c))
export const DownloadLinks = () => import('../../components/base/DownloadLinks.vue' /* webpackChunkName: "components/download-links" */).then(c => wrapFunctional(c.default || c))
export const DynamicForm = () => import('../../components/base/DynamicForm.vue' /* webpackChunkName: "components/dynamic-form" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../components/base/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/base/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const PortalModal = () => import('../../components/base/PortalModal.vue' /* webpackChunkName: "components/portal-modal" */).then(c => wrapFunctional(c.default || c))
export const StickySideButton = () => import('../../components/base/StickySideButton.vue' /* webpackChunkName: "components/sticky-side-button" */).then(c => wrapFunctional(c.default || c))
export const SupportBanner = () => import('../../components/base/SupportBanner.vue' /* webpackChunkName: "components/support-banner" */).then(c => wrapFunctional(c.default || c))
export const ArticlesTile = () => import('../../components/articles/Tile.vue' /* webpackChunkName: "components/articles-tile" */).then(c => wrapFunctional(c.default || c))
export const ConcertDetailsModal = () => import('../../components/concert/DetailsModal.vue' /* webpackChunkName: "components/concert-details-modal" */).then(c => wrapFunctional(c.default || c))
export const ConcertFilters = () => import('../../components/concert/Filters.vue' /* webpackChunkName: "components/concert-filters" */).then(c => wrapFunctional(c.default || c))
export const ConcertForm = () => import('../../components/concert/Form.vue' /* webpackChunkName: "components/concert-form" */).then(c => wrapFunctional(c.default || c))
export const ConcertItem = () => import('../../components/concert/Item.vue' /* webpackChunkName: "components/concert-item" */).then(c => wrapFunctional(c.default || c))
export const FormulateButton = () => import('../../components/formulate/Button.vue' /* webpackChunkName: "components/formulate-button" */).then(c => wrapFunctional(c.default || c))
export const FormulateCalendar = () => import('../../components/formulate/Calendar.vue' /* webpackChunkName: "components/formulate-calendar" */).then(c => wrapFunctional(c.default || c))
export const FormulateDate = () => import('../../components/formulate/Date.vue' /* webpackChunkName: "components/formulate-date" */).then(c => wrapFunctional(c.default || c))
export const FormulateToggle = () => import('../../components/formulate/Toggle.vue' /* webpackChunkName: "components/formulate-toggle" */).then(c => wrapFunctional(c.default || c))
export const CarouselItem = () => import('../../components/carousel/Item.vue' /* webpackChunkName: "components/carousel-item" */).then(c => wrapFunctional(c.default || c))
export const Carousel = () => import('../../components/carousel/index.vue' /* webpackChunkName: "components/carousel" */).then(c => wrapFunctional(c.default || c))
export const HeroRadioVideo = () => import('../../components/hero/RadioVideo.vue' /* webpackChunkName: "components/hero-radio-video" */).then(c => wrapFunctional(c.default || c))
export const HeroSmall = () => import('../../components/hero/Small.vue' /* webpackChunkName: "components/hero-small" */).then(c => wrapFunctional(c.default || c))
export const IconsAdd = () => import('../../components/icons/Add.vue' /* webpackChunkName: "components/icons-add" */).then(c => wrapFunctional(c.default || c))
export const IconsApple = () => import('../../components/icons/Apple.vue' /* webpackChunkName: "components/icons-apple" */).then(c => wrapFunctional(c.default || c))
export const IconsArrowRight = () => import('../../components/icons/ArrowRight.vue' /* webpackChunkName: "components/icons-arrow-right" */).then(c => wrapFunctional(c.default || c))
export const IconsBroadcast = () => import('../../components/icons/Broadcast.vue' /* webpackChunkName: "components/icons-broadcast" */).then(c => wrapFunctional(c.default || c))
export const IconsCamera = () => import('../../components/icons/Camera.vue' /* webpackChunkName: "components/icons-camera" */).then(c => wrapFunctional(c.default || c))
export const IconsCheck = () => import('../../components/icons/Check.vue' /* webpackChunkName: "components/icons-check" */).then(c => wrapFunctional(c.default || c))
export const IconsChecked = () => import('../../components/icons/Checked.vue' /* webpackChunkName: "components/icons-checked" */).then(c => wrapFunctional(c.default || c))
export const IconsChevronLeft = () => import('../../components/icons/ChevronLeft.vue' /* webpackChunkName: "components/icons-chevron-left" */).then(c => wrapFunctional(c.default || c))
export const IconsChevronRight = () => import('../../components/icons/ChevronRight.vue' /* webpackChunkName: "components/icons-chevron-right" */).then(c => wrapFunctional(c.default || c))
export const IconsChoir = () => import('../../components/icons/Choir.vue' /* webpackChunkName: "components/icons-choir" */).then(c => wrapFunctional(c.default || c))
export const IconsClock = () => import('../../components/icons/Clock.vue' /* webpackChunkName: "components/icons-clock" */).then(c => wrapFunctional(c.default || c))
export const IconsCloseForm = () => import('../../components/icons/CloseForm.vue' /* webpackChunkName: "components/icons-close-form" */).then(c => wrapFunctional(c.default || c))
export const IconsConcert = () => import('../../components/icons/Concert.vue' /* webpackChunkName: "components/icons-concert" */).then(c => wrapFunctional(c.default || c))
export const IconsDots = () => import('../../components/icons/Dots.vue' /* webpackChunkName: "components/icons-dots" */).then(c => wrapFunctional(c.default || c))
export const IconsDoubleChevronRight = () => import('../../components/icons/DoubleChevronRight.vue' /* webpackChunkName: "components/icons-double-chevron-right" */).then(c => wrapFunctional(c.default || c))
export const IconsEuro = () => import('../../components/icons/Euro.vue' /* webpackChunkName: "components/icons-euro" */).then(c => wrapFunctional(c.default || c))
export const IconsFacebookF = () => import('../../components/icons/FacebookF.vue' /* webpackChunkName: "components/icons-facebook-f" */).then(c => wrapFunctional(c.default || c))
export const IconsFacebookOrange = () => import('../../components/icons/FacebookOrange.vue' /* webpackChunkName: "components/icons-facebook-orange" */).then(c => wrapFunctional(c.default || c))
export const IconsGoogle = () => import('../../components/icons/Google.vue' /* webpackChunkName: "components/icons-google" */).then(c => wrapFunctional(c.default || c))
export const IconsHamburgerMenu = () => import('../../components/icons/HamburgerMenu.vue' /* webpackChunkName: "components/icons-hamburger-menu" */).then(c => wrapFunctional(c.default || c))
export const IconsHeadphone = () => import('../../components/icons/Headphone.vue' /* webpackChunkName: "components/icons-headphone" */).then(c => wrapFunctional(c.default || c))
export const IconsHome = () => import('../../components/icons/Home.vue' /* webpackChunkName: "components/icons-home" */).then(c => wrapFunctional(c.default || c))
export const IconsInstagram = () => import('../../components/icons/Instagram.vue' /* webpackChunkName: "components/icons-instagram" */).then(c => wrapFunctional(c.default || c))
export const IconsLinkedin = () => import('../../components/icons/Linkedin.vue' /* webpackChunkName: "components/icons-linkedin" */).then(c => wrapFunctional(c.default || c))
export const IconsLogoDesktop = () => import('../../components/icons/LogoDesktop.vue' /* webpackChunkName: "components/icons-logo-desktop" */).then(c => wrapFunctional(c.default || c))
export const IconsLogoEmblem = () => import('../../components/icons/LogoEmblem.vue' /* webpackChunkName: "components/icons-logo-emblem" */).then(c => wrapFunctional(c.default || c))
export const IconsLogoEmblemFull = () => import('../../components/icons/LogoEmblemFull.vue' /* webpackChunkName: "components/icons-logo-emblem-full" */).then(c => wrapFunctional(c.default || c))
export const IconsLogoMobile = () => import('../../components/icons/LogoMobile.vue' /* webpackChunkName: "components/icons-logo-mobile" */).then(c => wrapFunctional(c.default || c))
export const IconsLogout = () => import('../../components/icons/Logout.vue' /* webpackChunkName: "components/icons-logout" */).then(c => wrapFunctional(c.default || c))
export const IconsMagnifyingGlass = () => import('../../components/icons/MagnifyingGlass.vue' /* webpackChunkName: "components/icons-magnifying-glass" */).then(c => wrapFunctional(c.default || c))
export const IconsMapMarker = () => import('../../components/icons/MapMarker.vue' /* webpackChunkName: "components/icons-map-marker" */).then(c => wrapFunctional(c.default || c))
export const IconsMinus = () => import('../../components/icons/Minus.vue' /* webpackChunkName: "components/icons-minus" */).then(c => wrapFunctional(c.default || c))
export const IconsNews = () => import('../../components/icons/News.vue' /* webpackChunkName: "components/icons-news" */).then(c => wrapFunctional(c.default || c))
export const IconsNewspaper = () => import('../../components/icons/Newspaper.vue' /* webpackChunkName: "components/icons-newspaper" */).then(c => wrapFunctional(c.default || c))
export const IconsPaperPlane = () => import('../../components/icons/PaperPlane.vue' /* webpackChunkName: "components/icons-paper-plane" */).then(c => wrapFunctional(c.default || c))
export const IconsPauseButton = () => import('../../components/icons/PauseButton.vue' /* webpackChunkName: "components/icons-pause-button" */).then(c => wrapFunctional(c.default || c))
export const IconsPauseButtonSmall = () => import('../../components/icons/PauseButtonSmall.vue' /* webpackChunkName: "components/icons-pause-button-small" */).then(c => wrapFunctional(c.default || c))
export const IconsPerson = () => import('../../components/icons/Person.vue' /* webpackChunkName: "components/icons-person" */).then(c => wrapFunctional(c.default || c))
export const IconsPhone = () => import('../../components/icons/Phone.vue' /* webpackChunkName: "components/icons-phone" */).then(c => wrapFunctional(c.default || c))
export const IconsPlayButton = () => import('../../components/icons/PlayButton.vue' /* webpackChunkName: "components/icons-play-button" */).then(c => wrapFunctional(c.default || c))
export const IconsPlayButtonSmall = () => import('../../components/icons/PlayButtonSmall.vue' /* webpackChunkName: "components/icons-play-button-small" */).then(c => wrapFunctional(c.default || c))
export const IconsPlus = () => import('../../components/icons/Plus.vue' /* webpackChunkName: "components/icons-plus" */).then(c => wrapFunctional(c.default || c))
export const IconsProgram = () => import('../../components/icons/Program.vue' /* webpackChunkName: "components/icons-program" */).then(c => wrapFunctional(c.default || c))
export const IconsQuestionmark = () => import('../../components/icons/Questionmark.vue' /* webpackChunkName: "components/icons-questionmark" */).then(c => wrapFunctional(c.default || c))
export const IconsRoheart = () => import('../../components/icons/Roheart.vue' /* webpackChunkName: "components/icons-roheart" */).then(c => wrapFunctional(c.default || c))
export const IconsTwitter = () => import('../../components/icons/Twitter.vue' /* webpackChunkName: "components/icons-twitter" */).then(c => wrapFunctional(c.default || c))
export const IconsVideo = () => import('../../components/icons/Video.vue' /* webpackChunkName: "components/icons-video" */).then(c => wrapFunctional(c.default || c))
export const LayoutsFooter = () => import('../../components/layouts/Footer.vue' /* webpackChunkName: "components/layouts-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutsHeader = () => import('../../components/layouts/Header.vue' /* webpackChunkName: "components/layouts-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutsMobileNavigation = () => import('../../components/layouts/MobileNavigation.vue' /* webpackChunkName: "components/layouts-mobile-navigation" */).then(c => wrapFunctional(c.default || c))
export const LayoutsNavigation = () => import('../../components/layouts/Navigation.vue' /* webpackChunkName: "components/layouts-navigation" */).then(c => wrapFunctional(c.default || c))
export const LayoutsSidebar = () => import('../../components/layouts/Sidebar.vue' /* webpackChunkName: "components/layouts-sidebar" */).then(c => wrapFunctional(c.default || c))
export const ModalsEnableTwoFactorModal = () => import('../../components/modals/EnableTwoFactorModal.vue' /* webpackChunkName: "components/modals-enable-two-factor-modal" */).then(c => wrapFunctional(c.default || c))
export const ProgramGrid = () => import('../../components/program/Grid.vue' /* webpackChunkName: "components/program-grid" */).then(c => wrapFunctional(c.default || c))
export const ProgramItem = () => import('../../components/program/Item.vue' /* webpackChunkName: "components/program-item" */).then(c => wrapFunctional(c.default || c))
export const ProgramPaywall = () => import('../../components/program/Paywall.vue' /* webpackChunkName: "components/program-paywall" */).then(c => wrapFunctional(c.default || c))
export const RadioBroadcast = () => import('../../components/radio/Broadcast.vue' /* webpackChunkName: "components/radio-broadcast" */).then(c => wrapFunctional(c.default || c))
export const RadioCarousel = () => import('../../components/radio/Carousel.vue' /* webpackChunkName: "components/radio-carousel" */).then(c => wrapFunctional(c.default || c))
export const RadioCarouselItem = () => import('../../components/radio/CarouselItem.vue' /* webpackChunkName: "components/radio-carousel-item" */).then(c => wrapFunctional(c.default || c))
export const RadioFixedPlayer = () => import('../../components/radio/FixedPlayer.vue' /* webpackChunkName: "components/radio-fixed-player" */).then(c => wrapFunctional(c.default || c))
export const RadioPlayer = () => import('../../components/radio/Player.vue' /* webpackChunkName: "components/radio-player" */).then(c => wrapFunctional(c.default || c))
export const RadioPlaylist = () => import('../../components/radio/Playlist.vue' /* webpackChunkName: "components/radio-playlist" */).then(c => wrapFunctional(c.default || c))
export const RadioPlaylistItem = () => import('../../components/radio/PlaylistItem.vue' /* webpackChunkName: "components/radio-playlist-item" */).then(c => wrapFunctional(c.default || c))
export const RadioStation = () => import('../../components/radio/Station.vue' /* webpackChunkName: "components/radio-station" */).then(c => wrapFunctional(c.default || c))
export const SearchForm = () => import('../../components/search/Form.vue' /* webpackChunkName: "components/search-form" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionDonationAmountForm = () => import('../../components/subscription/DonationAmountForm.vue' /* webpackChunkName: "components/subscription-donation-amount-form" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionSelectionForm = () => import('../../components/subscription/SelectionForm.vue' /* webpackChunkName: "components/subscription-selection-form" */).then(c => wrapFunctional(c.default || c))
export const Tabs = () => import('../../components/tabs/Index.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))
export const TabsTabContent = () => import('../../components/tabs/TabContent.vue' /* webpackChunkName: "components/tabs-tab-content" */).then(c => wrapFunctional(c.default || c))
export const TabsTabItem = () => import('../../components/tabs/TabItem.vue' /* webpackChunkName: "components/tabs-tab-item" */).then(c => wrapFunctional(c.default || c))
export const TemplatesDefault = () => import('../../components/templates/default.vue' /* webpackChunkName: "components/templates-default" */).then(c => wrapFunctional(c.default || c))
export const UserProfileForm = () => import('../../components/user/ProfileForm.vue' /* webpackChunkName: "components/user-profile-form" */).then(c => wrapFunctional(c.default || c))
export const UserRegistrationForm = () => import('../../components/user/RegistrationForm.vue' /* webpackChunkName: "components/user-registration-form" */).then(c => wrapFunctional(c.default || c))
export const VideoTile = () => import('../../components/video/Tile.vue' /* webpackChunkName: "components/video-tile" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
